@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
}

input{
    outline: none;    
}

body {
    margin: 0;
    min-width: 320px;
    min-height: 100vh;
    background-color: #f9fafb;
}